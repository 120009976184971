export let Store = {

    /**
     * @typedef {Object} HeaderItemValue
     * @property {string} label
     * @property {string} value
     * @property {boolean?} disabled
     * @property {color?} string
    */
    /**
     * @typedef {Object} HeaderItem
     * @property {string} id
     * @property {string} title
     * @property {string | HeaderItemValue} value
     * @property {"text" | "menu"} type
     * @property {boolean?} disabled
     * @property {function[]?} listeners
     * @property {object} props
    */
    /**
     * @typedef {Object} HeaderConfig
     * @property {string?} title
     * @property {HeaderItem[]} list
    */

    state: {
        loginTenantDetails: null,
        allJurisdiction: [],
        allCountryList: [],
        customHeader: null,
    },
    mutations: {
        SET_TENANT_DETAILS(state, payload) {
            state.loginTenantDetails = payload;
            document.title = payload.domain_name;
        },
        SET_JURISDICTION(state, payload) {
            state.allJurisdiction = payload;
        },
        SET_COUNTRY_LIST(state, payload) {
            state.allCountryList = payload;
        },
        /**
         * @param {HeaderConfig} payload 
         */
        SET_CUSTOM_HEADER(state, payload = null) {
          state.customHeader = payload;
        },
    },
    getters: {
        getLoginTenantDetails: (state) => state.loginTenantDetails,
        getAllStoresJurisdiction: (state) => state.allJurisdiction,
        getAllCountryList: (state) => state.allCountryList,
        getCustomHeader: (state) => state.customHeader,
    },
    actions: {
        setTenantDetails: ({ commit }, payload) => {
            commit("SET_TENANT_DETAILS", payload);
        },
        storeAllJurisdiction: ({commit}, payload) => {
            commit("SET_JURISDICTION", payload);
        },
        storeAllCountryList: ({commit}, payload) => {
            commit("SET_COUNTRY_LIST", payload);
        }
    },
};