// import axios from "../../light-ray/axios/index";
import Vue from "vue";
import { fetchRiskAssessmentMasterColor } from "../service";

export let riskAssessment = {
    state: {
        name:"riskAssessment",
        colorList: [],
        categoryList:[],
        updatedColors: [],
        editedRiskForm: {
            riskName: "",
            riskColor: {id: "", colour: "", disabled: false},
            riskDescription: "",
            riskMinValue:'',
            riskMaxValue:'',
            activeRisk: false,
            hasNameError: false,
            hasDescriptionError: false,
            hasColorError: false,
          },
        assessmentData: {},
        disabledDone: false,
        disabledSaveAndNext: false,
    },
    mutations: {
        SET_CATEGORY_LIST(state, payload) {
          state.categoryList = payload;
        },
        SET_RISK_COLORS_LIST(state, payload) {
          state.colorList = payload;
        },
        SET_UPDATED_COLOR_LIST(state, payload) {
          state.updatedColors = payload;
        },
        SET_RISK_NAME(state, payload) {
            state.editedRiskForm.riskName = payload;
        },
        SET_RISK_DESCRIPTION(state, payload) {
            state.editedRiskForm.riskDescription = payload;
        },
        SET_RISK_COLOR(state, payload) {
            state.editedRiskForm.riskColor = payload;
        },
        SET_RISK_ACTIVE(state, payload) {
            state.editedRiskForm.activeRisk = payload;
        },
        SET_RISK_MIN_VALUE(state, payload) {
            state.editedRiskForm.riskMinValue = payload;
        },
        SET_RISK_MAX_VALUE(state, payload) {
            state.editedRiskForm.riskMaxValue = payload;
        },
        SET_RISK_NAME_VALIDATION_ERROR(state, payload) {
            state.editedRiskForm.hasNameError = payload;
        },
        SET_RISK_DESCRIPTION_VALIDATION_ERROR(state, payload) {
            state.editedRiskForm.hasDescriptionError = payload;
        },
        SET_RISK_COLOR_VALIDATION_ERROR(state, payload) {
            state.editedRiskForm.hasColorError = payload;
        },
        SET_ASSESSMENT_DATA(state, payload) {
          state.assessmentData = payload;
        },
        SET_DISABLED_DONE(state, payload) {
          state.disabledDone = payload;
        },
        SET_DISABLED_SAVE_AND_NEXT(state, payload) {
          state.disabledSaveAndNext = payload;
        },
      },
      getters: {
        getCategoryList: (state) => state.categoryList,
        getCaseColorList: (state) => state.getCaseColorList,
        getUpdatedColorList: (state) => state.updatedColors,
        getColorList: (state) => state.colorList,
        getCurrentAssessmentData: (state) => state.assessmentData,
        getDisabledDone: (state) => state.disabledDone,
        getDisabledSaveAndNext: (state) => state.disabledSaveAndNext,
      },
      actions: {
        async fetchRiskColors({ commit, dispatch }) {
          try {
            const response = await fetchRiskAssessmentMasterColor();
            if (response.status) {
              let colorList = response?.data?.map((color) => ({
                ...color,
                disabled: false,
              }));
              commit("SET_RISK_COLORS_LIST", colorList);
              await dispatch("setDisabledRiskColor");
            } else {
              Vue.$toast.error(
                response?.data?.status_message || this.$t("general.default_error")
              );
            }
          } catch (err) {
            Vue.$toast.error(err.response?.data?.detail || this.$t("general.default_error"));
          }
        },
       async setDisabledRiskColor({ state, commit,}) {
            if (state?.categoryList?.length) {
                const usedColors = state.categoryList?.map((el) => el.color);
                let updatedColorList = state.colorList?.map((el) => {
                    if (usedColors.includes(el.colour)) {
                        return { ...el, disabled: true };
                    }
                    return el;
                });
                commit("SET_UPDATED_COLOR_LIST", updatedColorList);
            } else {
                commit("SET_UPDATED_COLOR_LIST", state.colorList);
            }
        },

        async updateAssessment({commit}, payload) {
          commit("SET_ASSESSMENT_DATA", payload);
        },
    },
};
    