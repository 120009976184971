import { isEmpty } from "lodash";
import {
  integratedCheckIdList,
  TOOLS_LICENSE_MAP,
} from "./constants";

const isFromSourcePostfix = '(so)';

function isResponseFromSource(label) {
    return label.endsWith(isFromSourcePostfix)
}

export function formatToolResponsePoolData(data) {
    return data.filter(x => !!x.value).map(i => {
        const label = i.value?.data?.label || ''
        return {
            url: i.value.config?.url,
            isFromSource: isResponseFromSource(label),
            label,
        }
    })
}

const addValueInSystemFields = (systemFields, fld, addressItem = {}) => {
    try {
      if (addressItem?.value) {
        if (fld.value !== null && fld.value !== "null") {
          if (!systemFields[addressItem.value][fld.field_name]) {
            systemFields[addressItem.value][fld.field_name] =
              fld.field_name === "nationality" || fld.field_name === "country"
                ? [fld.option_label]
                : [fld.value];
          } else {
            const field =
              fld.field_name === "nationality" || fld.field_name === "country"
                ? fld.option_label
                : fld.value;
            systemFields[addressItem.value][fld.field_name].push(field);
          }
        }
      } else {
        if (fld.value !== null && fld.value !== "null") {
          if (!systemFields[fld.field_name]) {
            systemFields[fld.field_name] =
              fld.field_name === "nationality" || fld.field_name === "country"
                ? [fld.option_label]
                : [fld.value];
          } else {
            const field =
              fld.field_name === "nationality" || fld.field_name === "country"
                ? fld.option_label
                : fld.value;
            systemFields[fld.field_name].push(field);
          }
        }
      }
    } catch (err) {
      console.log("err", err);
    }
};

export const prepareSystemFields = (response) => {
  const systemFields = {
    currentAddress: {},
    mailingAddress: {},
    permanentAddress: {},
    previousAddress: {},
  };

  const blockAddressFields = [
    { name: "Current Address", value: "currentAddress" },
    { name: "Mailing Address", value: "mailingAddress" },
    { name: "Permanent Address", value: "permanentAddress" },
    { name: "Previous Address", value: "previousAddress" },
  ];

  const addressField = [
    "address_line_1",
    "address_line_2",
    "locality",
    "postcode",
    "post_town",
    "nationality",
    "country",
  ];

  if (response?.data?.sections && response?.data?.sections?.length) {
    response.data.sections.forEach((section) => {
      // check each fields
      if (section.fields && section.fields.length) {
        section.fields.forEach((fld) => {
          addValueInSystemFields(systemFields, fld);
        });
      }
      // check each blocks
      if (section.blocks && section.blocks.length) {
        section.blocks.forEach((block) => {
          blockAddressFields.forEach((addressItem) => {
            if (block.block_title === addressItem.name) {
              if (block.fields) {
                block.fields.forEach((fld) => {
                  addValueInSystemFields(
                    systemFields,
                    fld,
                    addressItem
                  );
                });
              }
            }
          });
          // check each block fields
          if (block.fields) {
            block.fields.forEach((fld) => {
              addValueInSystemFields(systemFields, fld);
            });
          }
        });
      }
    });
  }

  

  if (isEmpty(systemFields.currentAddress)) {
    Object.keys(systemFields).forEach((field) => {
      if (addressField.includes(field)) {
        if (
          systemFields[field] !== null &&
          systemFields[field] !== "null"
        ) {
          if (!systemFields["currentAddress"][field]) {
            systemFields["currentAddress"][field] = [
              systemFields[field][0],
            ];
          } else {
            systemFields["currentAddress"][field].push(
              systemFields[field][0]
            );
          }
        }
      }
    });
  }

  return systemFields;
};

// * In case integrated check is the same as the tool and require same licenses we need to set an integrated check name equal to the tool name at `integratedCheckList`
export const getIntegratedCheckName = (checkData = {}) => {
  if (!checkData) return null;

  const { integration_source_id } = checkData;
  if (!integration_source_id) return null;

  const name = Object.keys(integratedCheckIdList).find((key) => integratedCheckIdList[key] === integration_source_id);

  return name ?? null;
}

export const getToolLicenseList = (toolName) => {
  return TOOLS_LICENSE_MAP[toolName] ?? null;
}