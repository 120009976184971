//  Import Routes
// import { ProfileRoutes } from "./modules/profile/routes";
import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
// import { validateAndSetAuth } from "@/modules/auth/service";
import { FormAdmin } from "@/modules/form-admin/routes";
// import { RolesAndPermissions } from "@/modules/roles-and-permissions/routes"
import { LightRayRoutes } from "@shared/light-ray/routes"
import { License } from "@/modules/license/routes"
import { checkPermission ,getParamsFromUrl} from "@shared/utils/functions"
import store from "@/store"


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "dashboard.view",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/pages/dashboard"),
        redirect: { name: "Dashboard - Page 1" },
        children: [
            {
                path: "case-dashboard",
                name: "Dashboard - Page 1",
                component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/dashboard/pages/case-dashboard"),
                // component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/bi-dashboard/bi-dashboard"),
                meta: {
                    title: "",
                },
            },
            {
                path: "default-dashboard/:id",
                name: "Dashboard - Page 2",
                component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/dashboard/pages/case-dashboard"),
                meta: {
                    breadcrumb: [{ name: 'dashboard List"', tName: 'routes.dashboard_list'}],
                    title: "",
                },
            },

            {
                path: "/bi-dashboard-view/:id",
                name: "Bi-Dashboard-View",
                component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/bi-dashboard/bi-dashboard-view"),
                meta: {
                    title: "",
                },
            },
            {
                path:"case/landing/:case_id",
                name: "lightray-redirect",
                component: () => import(/* webpackChunkName: "login" */ "../pages/lightray-redirection"),
            },
            {
                // to be changed to be something more logical according to route
                path: "email-admin",
                name: "email-admin",
                meta: {
                    title: 'Email Admin', tTitle: 'routes.email_admin',
                },
                component: () => import(/* webpackChunkName: "email-admin" */ "../pages/email-admin"),
            },
            {
                // to be changed to be something more logical according to route
                path: "default-template",
                name: "default-template",
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Emails"', tName: 'routes.emails'}, {name: 'Default Templates/Sequences', tName: 'routes.default_templatessequences'}],
                    title: 'Default Email', tTitle: 'routes.default_email',
                },
                component: () => import(/* webpackChunkName: "email-admin" */ "../pages/default-template"),
            },
            {
                // to be changed to be something more logical according to route
                path: "2",
                name: "Dashboard - Page 2",
                component: () => import(/* webpackChunkName: "dashboard-page2" */ "../pages/dashboard/pages/page2"),
            },
            {
                name: "Fields",
                path: "/field-admin",
                component: () => import(/* webpackChunkName: "field-admin" */ "../pages/field-table/field-tab"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Field List"', tName: 'routes.field_list'}],
                    title: 'Field Admin', tTitle: 'routes.field_admin',
                },
            },
            {
                name: "Field Setting",
                path: "/field-settings/:id/:action",
                component: () => import(/* webpackChunkName: "field-settings" */ "../pages/field-table/field-admin-view"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Field List"', tName: 'routes.field_list',clickable:true , goTo:'/field-admin?tab=fieldadmin' }, { name: 'Field Info"', tName: 'routes.field_info'}],
                    title: 'Field Details', tTitle: 'routes.field_details',
                    requiredPermission: 'field.edit',
                },
                props: true,
            },
            {
                name: "Create package",
                path: "/create-package",
                component: () => import(/* webpackChunkName: "create-package" */ "../pages/create-package"),
                props: true,
                meta: {
                    breadcrumb: [{ name: 'packages"', tName: 'routes.packages'}, { name: 'create"', tName: 'routes.create'}],
                    title: 'Create Package', tTitle: 'routes.create_package',
                },
            },
            {
                name: "Add Field",
                path: "/add-field/:field?",
                component: () => import(/* webpackChunkName: "add-field" */ "../pages/field-table/field-admin-view"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Field List"', tName: 'routes.field_list'}, { name: 'Create Field"', tName: 'routes.create_field'}],
                    title: 'Add Field', tTitle: 'routes.add_field',
                },
                props: true,
            },
            {
                name: "check Admin",
                path: "/check-admin",
                component: () => import(/* webpackChunkName: "check-admin" */ "../pages/check-admin"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Check List"', tName: 'routes.check_list'}],
                    title: 'Check Admin', tTitle: 'routes.check_admin',
                },
            },
            {
                name: "check admin details",
                path: "/check-admin/view/:id/setting/:action/:workflowId?/:type?/:workflowTool?",
                component: () => import(/* webpackChunkName: "company-admin-view-settings" */ "../pages/check-admin/check-dragable-section"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Check List"', tName: 'routes.check_list',clickable:true , goTo:'/check-admin'}, { name: 'Check Info"', tName: 'routes.check_info'}, { name: 'Check Edit"', tName: 'routes.check_edit'}],
                    title: 'Check Details', tTitle: 'routes.check_details',
                    requiredPermission: 'check.edit'
                },
                // component: () => import("../pages/check-admin/check-dragable-section-dragable-field"),
            },
            {
                name: "check View",
                path: "/check-admin/view/:id",
                component: () => import(/* webpackChunkName: "check-admin-view" */ "../pages/check-admin/check-view"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Check List"', tName: 'routes.check_list'}, { name: 'Check Info"', tName: 'routes.check_info'}],
                    title: 'Check Fields', tTitle: 'routes.check_fields',
                },
            },
            {
                name: "Users",
                path: "/users",
                component: () => import(/* webpackChunkName: "users" */ "../pages/user-details/index.vue"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Users"', tName: 'routes.users'}],
                    title: 'Users', tTitle: 'routes.users',
                },
            },
            {
                name: "Registered Domain",
                path: "/registered-domain",
                component: () => import(/* webpackChunkName: "users" */ "../pages/RegisteredDomain/index.vue"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Registered Domain"', tName: 'routes.registered_domain'}],
                    title: 'Registered Domain', tTitle: 'routes.registered_domain',
                },
            },
            {
                name: "User Setting",
                path: "/users/:id",
                component: () => import(/* webpackChunkName: "user-settings" */ "../pages/user-details/userTableDataView"),
                props: true,
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Users"', tName: 'routes.users', giveAccess: true , goTo:'/users'}, { name: 'User Details"', tName: 'routes.user_details'}],
                    title: 'User Details', tTitle: 'routes.user_details',
                    requiredPermission: 'users.read'
                },
            },
            {
                name: "Group Details",
                path: "/groups",
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Groups"', tName: 'routes.groups'}],
                    title: 'Group Details', tTitle: 'routes.group_details',
                },
                component: () => import(/* webpackChunkName: "group-details" */ "../pages/group-details"),
            },
            {
                name: "Group Setting",
                path: "/group-settings/:id",
                component: () => import(/* webpackChunkName: "group-settings" */ "../pages/group-details/groupTableDataView"),
                props: true,
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Groups"', tName: 'routes.groups', giveAccess: true , goTo:'/groups' }, { name: 'Group Details"', tName: 'routes.group_details'}],
                    title: 'Group Details', tTitle: 'routes.group_details',
                    requiredPermission: 'groups.read'
                },
            },
            {
                name: "Profile Container",
                path: "/case/:caseid/profile-view",
                component: () => import(/* webpackChunkName: "profile-view" */ "../pages/profile-view"),
                meta: {
                    showCaseInfo: true,
                },
            },
            {
                name: "Workflow Engine Case",
                path: "workflow-engine/case",
                component: () => import(/* webpackChunkName: "case-status" */ "../pages/workflow-engine/case-status"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}, { name: 'case"', tName: 'routes.case'}],
                    title: 'Workflow Case Status', tTitle: 'routes.workflow_case_status',
                },
            },
            {
                name: "Workflow Engine Person",
                path: "workflow-engine/person",
                component: () => import(/* webpackChunkName: "case-status" */ "../pages/workflow-engine/case-status"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}, { name: 'person"', tName: 'routes.person'}],
                    title: 'Workflow Person Status', tTitle: 'routes.workflow_person_status',
                },
            },
            {
                name: "Workflow Engine Company",
                path: "workflow-engine/company",
                component: () => import(/* webpackChunkName: "case-status" */ "../pages/workflow-engine/case-status"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}, { name: 'company"', tName: 'routes.company'}],
                    title: 'Workflow Company Status', tTitle: 'routes.workflow_company_status',
                },
            },
            {
                name: "Workflow Engine Checks",
                path: "workflow-engine/check",
                component: () => import(/* webpackChunkName: "case-status" */ "../pages/workflow-engine/check-status"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}, { name: 'check"', tName: 'routes.check'}],
                    title: 'Workflow Check Status', tTitle: 'routes.workflow_check_status',
                },
            },
            {
                name: "Create Case Form",
                path: "/create-case-form/:id",
                component: () => import(/* webpackChunkName: "case-creation-form" */ "../pages/create-case/createCaseForm"),
            },
            {
                name: "Create Case",
                path: "/create-case",
                component: () => import(/* webpackChunkName: "create-case" */ "../pages/create-case"),
                meta: {
                    title: 'Case Creation"', tTitle: 'routes.case_creation'
                }
            },
            {
                name: "pending-activity",
                path: "/activity",
                component: () => import(/* webpackChunkName: "pending-activity" */ "../pages/activity-history-notification"),
                meta: {
                    title: 'Pending Activities', tTitle: 'routes.pending_activities',
                }
            },
            {
                name: "Clients List",
                path: "/clients-list",
                component: () => import(/* webpackChunkName: "client-list" */ "@/pages/tenant-client/client-list"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Client List"', tName: 'routes.client_list'}],
                    title: 'Clients', tTitle: 'routes.clients',
                },
            },
            {
                name:"salesOrdersList",
                path: "/sales-orders",
                component: () => import(/* webpackChunkName: "sales-orders" */ "@/pages/sales-orders/orders-list"),
                meta: {
                    breadcrumb: [{name: 'Tenant Admin', tName: 'routes.tenant_admin'}, {name: 'Sale Orders', tName: 'routes.sale_orders'}],
                    title: 'Sales"', tTitle: 'routes.sales'
                }
            },
            {
                name:"salesOrderInfo",
                path: "/order-info/:id?/:client_id?",
                component: () => import(/* webpackChunkName: "sales-orders" */ "@/pages/sales-orders/create-order/create-orders"),
                meta: {
                    breadcrumb: [{name: 'Tenant Admin', tName: 'routes.tenant_admin'}, {name: 'Sale Orders', tName: 'routes.sale_orders'}],
                    title: 'Sales"', tTitle: 'routes.sales'}
            },
            {
                name: "source_details",
                path: 'company-domain',
                component: () => import(/* webpackChunkName: "companyDomain" */ "@/pages/credit-packs/credit-source-list/company-domain"),
                meta: {
                    breadcrumb: [{name: 'Admin', tName: 'routes.admin'}, {name: 'Feature', tName: 'routes.feature'}],
                    title: 'Feature', tTitle: 'routes.feature',
                }
            },
            {
                name: "client",
                path: "/clients",
                component: () => import(/* webpackChunkName: "create-client" */ "@/pages/tenant-client/create-client"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Client List"', tName: 'routes.client_list'}, { name: 'Create Client"', tName: 'routes.create_client'}],
                    title: 'Create Client', tTitle: 'routes.create_client',
                },
                children: [
                    {
                        name: "create-client",
                        path: "create-client/:tab?",
                        meta: {
                            breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Create Client"', tName: 'routes.create_client'}],
                            customCrumbs: [],
                            title: 'Client', tTitle: 'routes.client',
                        },
                    },
                    {
                        name: "client-details",
                        path: ":clientId/:tab?",
                        meta: {
                            breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Client List', tName: 'routes.client_list', giveAccess: true, goTo: '/clients-list' }, { name: 'Client Info"', tName: 'routes.client_info'}],
                            customCrumbs: [],
                            title: 'Client', tTitle: 'routes.client',
                            requiredPermission: 'form.read' 
                        },
                    },
                    
                ]
            },
            {
                name: "Edit Client",
                path: "/client/:id",
                component: () => import(/* webpackChunkName: "edit-client" */ "../pages/create-client"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Client List"', tName: 'routes.client_list'}, { name: 'Client Info"', tName: 'routes.client_info'}],
                    title: 'Client Details', tTitle: 'routes.client_details',
                },
            },
            {
                name: "Screening Package",
                path: "/packages",
                component: () => import(/* webpackChunkName: "screening-packages" */ "../pages/tenant-packages"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Package List"', tName: 'routes.package_list'}],
                    title: 'Packages', tTitle: 'routes.packages',
                },
            },
            {
                name: "Screening Package View",
                path: "/packages/:packageName/:action",
                component: () => import(/* webpackChunkName: "screening-packages-view" */ "../pages/tenant-packages/package-details"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Package List"', tName: 'routes.package_list'}, { name: 'Package Info"', tName: 'routes.package_info'}],
                    title: 'Package Details', tTitle: 'routes.package_details',
                },
                children: [
                    {
                        path: ":packagetool?/:packagetoolId?/:type?",
                        name: "Package Tools",
                        meta: {
                            breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Package List"', tName: 'routes.package_list', clickable:true , goTo:'/packages'}, { name: 'Package Info"', tName: 'routes.package_info'}],
                            customCrumbs: [],
                            title: 'Package Tool Details', tTitle: 'routes.package_tool_details',
                            requiredPermission: 'package.edit'
                        },
                    },
                ],
            },
            {
                name: "Risk Assessment",
                path: "/risk-assessment",
                component: () => import(/* webpackChunkName: "risk-assessment" */ "../pages/risk-assessment"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Risk Assessment"', tName: 'routes.risk_assessment'}],
                    title: 'Risk Assessment', tTitle: 'routes.risk_assessment',
                },
            },
            {
                name: "Risk Assessment Details",
                path: "/risk-assessment/:action?/:id?",
                component: () => import(/* webpackChunkName: "risk-assessment-details" */ "../pages/risk-assessment/risk-assessment-details"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Risk Assessment"', tName: 'routes.risk_assessment'}],
                    title: 'Risk Assessment Details', tTitle: 'routes.risk_assessment_details',
                },
            },
            {
                path: "/risk-assessment/:action?/:id?/:assessment_action?/:assessmentId?",
                name: "Assessment Details",
                component: () => import(/* webpackChunkName: "risk-assessment-details" */ "../pages/risk-assessment/components/assessment-details"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Risk Assessment', tName: 'routes.risk_assessment'}, { name: 'Assessment"', tName: 'routes.assessment'}],
                    customCrumbs: [],
                    title: 'Assessment Details', tTitle: 'routes.assessment_details',
                    requiredPermission: 'package.edit'
                },
            },
            {
                path: "workflow-engine/:type/:action/status-configure/:statusId/:workflowId",
                name: "Case Status Configure",
                component: () => import(/* webpackChunkName: "case-status" */ "../pages/workflow-engine/case-status/status-configure"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}],
                    title: 'Status Configure', tTitle: 'routes.status_configure',
                    customCrumbs: [],
                }
            },
            
            {
                name: "Workflow Engine Details",
                path: "workflow-engine/:type/:action",
                component: () => import(/* webpackChunkName: "case-status" */ "../pages/workflow-engine/case-status/create-case-workflow"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}],
                    title: 'Workflow Case Details', tTitle: 'routes.workflow_case_details',
                },
                children: [
                    {
                        path: ":workflowTool?/:workflowId?/:toolId?/:trigger_action?",
                        name: "Workflow Tools",
                        meta: {
                            breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'} ],
                            customCrumbs: [],
                            title: 'Workflow Details', tTitle: 'routes.workflow_details',
                        },
                    },
                ],
            },
            {
                name: "Report Templates",
                path: "/report-templates",
                component: () => import(/* webpackChunkName: "check-admin" */ "../pages/report-templates"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Report Templates"', tName: 'routes.report_templates'}],
                    title: 'Report Templates', tTitle: 'routes.report_templates',
                },
            },
            {
                name: "Create Report Template",
                path: "/report-templates/create",
                component: () => import(/* webpackChunkName: "check-admin-view" */ "../pages/report-templates/create-template"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Report Templates"', tName: 'routes.report_templates'}, { name: 'Create Report Template"', tName: 'routes.create_report_template'}],
                    title: 'Create Report Template', tTitle: 'routes.create_report_template',
                },
            },
            {
                name: "Edit Report Template",
                path: "/report-templates/edit/:id",
                component: () => import(/* webpackChunkName: "check-admin-view" */ "../pages/report-templates/create-template"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Report Templates"', tName: 'routes.report_templates'}, { name: 'Edit Report Template"', tName: 'routes.edit_report_template'}],
                    title: 'Edit Report Template', tTitle: 'routes.edit_report_template',
                },
            },
            // {
            //     path: "workflow-engine/:type/:action/status-configure/:statusId/:workflowId",
            //     name: "Case Status Configure",
            //     component: () => import(/* webpackChunkName: "case-status" */ "../pages/workflow-engine/case-status/status-configure"),
            //     meta: {
            //         breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}],
            //         title: 'Status Configure', tTitle: 'routes.status_configure',
            //         customCrumbs: [],
            //     }
            // },
            // {
            //     path: "workflow-engine/check-status/create-check-workflow/:statusName/configure",
            //     name: "Check Status Configure",
            //     component: () => import(/* webpackChunkName: "case-status" */ "../pages/workflow-engine/check-status/status-configure"),
            //     meta: {
            //         breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}, { name: 'Check"', tName: 'routes.check'}, { name: 'Create Workflow"', tName: 'routes.create_workflow'}, { name: 'Status Transition"', tName: 'routes.status_transition'}, { name: 'Configure Status"', tName: 'routes.configure_status'}],
            //         title: 'Status Configure', tTitle: 'routes.status_configure',
            //         customCrumbs: [],
            //     }
            // },
            // {
            //     name: "Workflow Engine Check View",
            //     path: "workflow-engine/checks/create-check-workflow",
            //     component: () => import(/* webpackChunkName: "case-status" */ "../pages/workflow-engine/check-status/create-check-workflow"),
            //     meta: {
            //         breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}, { name: 'case"', tName: 'routes.case'}],
            //         title: 'Workflow Case Details', tTitle: 'routes.workflow_case_details',
            //     },
            //     children: [
            //         {
            //             path: ":workflowTool?",
            //             name: 'Workflow Tools', tName: 'routes.workflow_tools',
            //             meta: {
            //                 breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Workflow Engine"', tName: 'routes.workflow_engine'}, { name: 'Case"', tName: 'routes.case'}, { name: 'Create Workflow"', tName: 'routes.create_workflow'}],
            //                 customCrumbs: [],
            //                 title: 'Workflow Details', tTitle: 'routes.workflow_details',
            //             },
            //         },
            //     ],
            // },
            {
                name: "File Browser",
                path: "/file-browser",
                component: () => import(/* webpackChunkName: "file-browser" */ "@/pages/file-browser"),
            },
            {
                name: "Custom Domain",
                path: "/custom-domain",
                component: () => import(/* webpackChunkName: "file-browser" */ "@/pages/custom-domain"),
                meta: {
                    title: 'Custom Domain', tTitle: 'routes.custom_domain',
                }
            },
            {
                name: "Configurations",
                path: "/configurations",
                component: () => import(/* webpackChunkName: "file-browser" */ "@/pages/configurations"),
                meta: {
                    breadcrumb: [{ name: 'Settings"', tName: 'routes.settings'}, { name: 'Configurations"', tName: 'routes.configurations'}],
                    title: 'Configurations', tTitle: 'routes.configurations',
                }
            },
            {
                name: "block-admin-details",
                path: "/block-admin/:id/:action",
                component: () => import(/* webpackChunkName: "idcheck-confirmation" */ "../pages/block-admin/block-admin-details"),
                meta: {
                    title: 'Block Admin', tTitle: 'routes.block_admin',
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Block List"', tName: 'routes.block_list', clickable:true , goTo:'/field-admin?tab=blockadmin' }, { name: 'Block Details"', tName: 'routes.block_details'}],
                    requiredPermission: 'block.edit',
                },
            },
            {
                name: "App Settings: Logo And Theme",
                path: "/logo-and-theme",
                component: () => import(/* webpackChunkName: "app-settings" */ "../pages/theme-admin"),
            },
            {
                name: "unauthenticated",
                path: "/unauthenticated",
                component: () => import(/* webpackChunkName: "not-found" */ "@shared/components/unauthenticated")
            },
            {
                name: "Credit Packs",
                path: "/credit-packs",
                component: () => import(/* webpackChunkName: "roles and permissions" */ "@/pages/credit-packs/index.vue"),
                meta: {
                    breadcrumb: [{ name: 'Tenant Admin"', tName: 'routes.tenant_admin'}, { name: 'Credit Packs"', tName: 'routes.credit_packs'}],
                    title: 'Credit Packs', tTitle: 'routes.credit_packs',
                },
            },
            {
                name: "Allocate Credits",
                path: "/allocate-credits",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/allocate-pack"),
                meta: {
                    title: 'Credit Admin', tTitle: 'routes.credit_admin',
                    breadcrumb:[{ name: 'Tenant Admin"', tName: 'routes.tenant_admin'}, { name: 'Credit Pack"', tName: 'routes.credit_pack'}, { name: 'Allocate Credit Pack"', tName: 'routes.allocate_credit_pack'}],
                },
            },
            {
                name: "allotedcredit",
                path: "/allocate-credits/:id",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/update-allotted-pack"),
                meta: {
                    title: 'Credit Admin', tTitle: 'routes.credit_admin',
                    breadcrumb:[{ name: 'Tenant Admin"', tName: 'routes.tenant_admin'}, { name: 'Credit Pack"', tName: 'routes.credit_pack'}, { name: 'Modify Allocate Credit Pack"', tName: 'routes.modify_allocate_credit_pack'}],
                },
            },
            {
                name: "Pack Details",
                path: "/pack-details/:id",
                component: () => import(/* webpackChunkName: "fpack-details" */ "@/pages/credit-packs/pack-details/index.vue"),
                meta: {
                    breadcrumb: [{ name: 'Tenant Admin"', tName: 'routes.tenant_admin'}, { name: 'Credit Packs"', tName: 'routes.credit_packs'}, { name: 'Pack Details"', tName: 'routes.pack_details'}],
                    title: 'Pack Details', tTitle: 'routes.pack_details',
                },
                props: true,
            },
            {
                name: "inventoryDetails",
                path: "/inventory-details/:id",
                component: () => import(/* webpackChunkName: "user-details" */ "@/pages/credit-packs/inventory-details"),
                meta: {
                    title: 'Credit Admin', tTitle: 'routes.credit_admin',
                    breadcrumb:[{ name: 'Tenant Admin"', tName: 'routes.tenant_admin'}, { name: 'Credit Pack"', tName: 'routes.credit_pack'}, { name: 'Inventory Details"', tName: 'routes.inventory_details'}],
                },
            },
            {
                name: "Purchase Order",
                path: "/purchase-order",
                component: () => import(/* webpackChunkName: "app-settings" */ "../pages/purchase-order/orders-list.vue"),
                meta: {
                    breadcrumb: [{ name: 'Purchase"', tName: 'routes.purchase'}, { name: 'Purchase Orders"', tName: 'routes.purchase_orders'}],
                }
            },
            {
                name: "purchseOrderInfo",
                path: '/orders-info/:id?',
                component: () => import(/* webpackChunkName: "createCurrency" */ "../pages/purchase-order/create-order/create-orders.vue"),
                meta: {
                    breadcrumb: [{ name: 'Purchase"', tName: 'routes.purchase'}, { name: 'Purchase Orders"', tName: 'routes.purchase_orders'}, {name: 'Create Order', tName: 'routes.create_order'}],
                    requiredPermission: 'purchase.read'
                }
            },
            {
                name: "Manage Tax",
                path: "/manage-tax",
                component: () => import(/* webpackChunkName: "roles and permissions" */ "@/pages/manage-tax/index.vue"),
                meta: {
                    breadcrumb: [{ name: 'Tenant Admin"', tName: 'routes.tenant_admin'}, { name: 'Manage Tax"', tName: 'routes.manage_tax'}, { name: 'Manage Tax List"', tName: 'routes.manage_tax_list'}],
                    title: 'Tax Admin', tTitle: 'routes.tax_admin',
                    customClass: "Prerana"
                },
            },
            {
                name: "manageTaxInfo",
                path: '/manage-tax-info/:id?',
                component: () => import(/* webpackChunkName: "taxList" */ "../pages/manage-tax/manage-tax-info"),
                meta: {
                    breadcrumb: [{ name: 'Tenant Admin"', tName: 'routes.tenant_admin'}, { name: 'Manage Tax"', tName: 'routes.manage_tax'}],
                    requiredPermission: 'tax.edit'
                }
            },

          
            
            // Module Routes
            ...FormAdmin,
            ...License,
            // ...RolesAndPermissions,
            {
                path: "/roles-and-permissions",
                component: () => import(/* webpackChunkName: "roles and permissions" */ "@/pages/roles-and-permissions/index.vue"),
                meta: {
                    breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Roles and Permissions"', tName: 'routes.roles_and_permissions'}],
                    title: 'Roles and Permissions', tTitle: 'routes.roles_and_permissions',
                },
                children: [
                    {
                        path: "/",
                        name: "roles-and-permissions-list",
                        component: () => import(/* webpackChunkName: "roles and permissions list" */ "@/pages/roles-and-permissions/roles-and-permissions-list"),
                        meta: {
                            breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Roles and Permissions"', tName: 'routes.roles_and_permissions'}],
                            title: 'Roles and Permissions', tTitle: 'routes.roles_and_permissions',
                        },
                    },
                    {
                        path: "details/:id",
                        name: "roles-and-permissions-details",
                        component: () => import(/* webpackChunkName: "roles and permissions details" */ "@/pages/roles-and-permissions/roles-and-permissions-details"),
                        meta: {
                            breadcrumb: [{ name: 'Admin"', tName: 'routes.admin'}, { name: 'Roles and Permissions"', tName: 'routes.roles_and_permissions'}, {name: 'details', tName: 'routes.details'}],
                            title: 'Roles and Permissions', tTitle: 'routes.roles_and_permissions',
                            requiredPermission: 'role.edit'
                        },
                    },
                ],
            },
            ...LightRayRoutes,
            {
                name: 'Access Denied',
                path: "/access-denied",
                component: () => import(/* webpackChunkName: "additional-forms" */ "@shared/access-denied")
            }
        ],
    },
    {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "login" */ "@/pages/login"),
    },
    {
        path: "/otp-login",
        name: "otp-login",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/otpScreen"),
        meta: {
            title: 'otp-login', tTitle: 'routes.otplogin',
        },
    },
    {
        path: "/unified-portal-login",
        name: "UnifiedPortalLogin",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/UnifiedPortalLogin.vue"),
        meta: {
            title: 'Unified Portal Login', tTitle: 'routes.unified_portal_login',
        },
    },
    {
        path: "/self-registration",
        name: "SelfRegistration",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/SelfRegistration"),
        meta: {
            title: 'Self Registration', tTitle: 'routes.self_registration',
        },
    },
    {
        path: "/self-registration-user-detail",
        name: "SelfRegistrationUserDetail",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/SelfRegistration/UserDetail.vue"),
        meta: {
            title: 'Self Registration User Detail', tTitle: 'routes.self_registration_user_detail',
        },
    },
    {
        path: "/update-password",
        name: "update-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/passwordUpdate"),
        meta: {
            title: 'update-password', tTitle: 'routes.updatepassword',
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/forgotPasswordPage"),
        meta: {
            title: 'forgot-password', tTitle: 'routes.forgotpassword',
        },
    },
    {
        name: "Email Template Admin",
        path: "/email-template-admin",
        component: () => import(/* webpackChunkName: "email-template-admin" */ "../pages/email-template"),
        meta: {
            title: 'Email Admin', tTitle: 'routes.email_admin',
        },
    },
    {
        name: "Email Template",
        path: "/email-template-admin/:folder",
        component: () => import(/* webpackChunkName: "email-template-admin-view" */ "../pages/email-template/email-lists"),
    },
    {
        name: "LoginCallback",
        path: "/login-success",
        component: () => import(/* webpackChunkName: "login-callback" */ "../modules/auth/login-success"),
        props: true,
    },
    {
        name: "Form Builder",
        path: "/form/preview/:package",
        component: () => import(/* webpackChunkName: "form-builder" */ "../pages/form-builder"),
    },
    {
        name: "SC Journey",
        path: "/sc-journey",
        component: () => import(/* webpackChunkName: "sc-journey" */ "../pages/sc-journey"),
    },
    {
        name: "not-found",
        path: "*",
        component: () => import(/* webpackChunkName: "not-found" */ "../pages/not-found"),
    },
  
    {
        path: "/dependant-fields",
        component: () => import(/* webpackChunkName: "dependant-fields" */ "../components/dependant-fields"),
    },
    {
        path: "/idcheck-confirmation",
        name: "IDCheckConfirmation",
        component: () => import(/* webpackChunkName: "idcheck-confirmation" */ "@shared/idcheck-confirmation"),
    },
    {
        name: "AdditionalForm",
        path: "/additional-form/preview/:form_id",
        component: () => import(/* webpackChunkName: "additional-forms" */ "../pages/additional-forms"),
    },
];

const router = new VueRouter({
    // base:process.env.VUE_APP_BASE_URL_TENANT,
    routes,
    mode: "history",
});

router.onError((error) => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload();
    }
});

/// AUTH MIDDLEWARE

router.beforeEach(async (to, from, next) => {
    let restrictedPage = ['Field Setting','block-admin-details', 'check admin details', 'form admin details', 'client-details' , 'Group Setting', 'User Setting', 'manageTaxInfo', 'license-details', 'roles-and-permissions-details', 'Package Tools']
    checkPermission;
    // GET AUTH TOKEN
    const access_token = VueCookies.get("token");
    // const refresh_token = VueCookies.get("refresh_token");
    const params = getParamsFromUrl();
    if (access_token && (from.name === null || from.name === "LoginCallback")) {
        // if user directly came to any specific url
        // await validateAndSetAuth(access_token, refresh_token);
        // interceptResponse();
        if (store.getters.getUserPermissions && !store.getters.getUserPermissions.length && access_token) {
            await store.dispatch("fetchPermissions")
        }
        next();
    }
    if (!access_token) {

        if (to.name === "LoginCallback" || to.name === "Login" || to.name === 'unauthenticated' || to.name === 'forgot-password' || to.name === 'update-password' || to.name === 'otp-login' || to.name === 'new-user-login' ||  to.name === 'UnifiedPortalLogin' || to.name === 'SelfRegistration' || to.name === "SelfRegistrationUserDetail") {
            next();
        } else {
            // If not logged in
            // Trying to navigate to another page, send to login but keep next-url.
            // VueCookies.set("next-url", to.fullPath);
            next({ name: "Login" ,params: params});
        }
        next();
    } else {
        restrictedPage.includes(to?.name) ? await store.dispatch("fetchPermissions") : "";
        if (to.name === "LoginCallback" || to.name === "Login" || to.name === "Dashboard.view") {
            // If logged in
            // Trying to open the Login page, you be crazy!
            // interceptResponse();
            next({ name: "dashboard.view" });
        } 
        // else if(restrictedPage.includes(to?.name) && !checkPermission(to?.meta?.requiredPermission) ) {
        //     if(to?.params?.action !== 'view') {
        //         console.log("from", from);
        //         if(from !== null) {
        //             sessionStorage.setItem("redirectUrlAfterAccess", from.fullPath);
        //         }
        //         console.log("redirectUrlAfterAccess")
        //         // next({ name: "Access Denied" });
        //         next();
        //     }
        //     next();
        // } 
        else {
            // All systems good to go!
            // interceptResponse();
           next();
        }
    }
});

export default router;

