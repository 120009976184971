import axios from "../../light-ray/axios/index";
import Vue from "vue";
import { WORK_FLOW_TYPE } from "../constant";
import {
  fetchWorkflowMasterColors,
  fetchStateTransitionList,
} from "../service";

export let WorkFlowStore = {
  state: {
    name: "workFlowStore",
    tenantId: "",
    caseStatusList: [],
    colorList: [],
    updatedColors: [],
    triggerList: [],
    actionList: [],
    triggersAndActionData: [],
    transitionList: [],
    loaders: {
      createStatus: false,
      colorList: false,
      statusList: false,
      triggerAndAction: false,
      triggerList: false,
      actionList: false,
      triggersAndActionData: false,
      // New design
      // caseStatusList
      transitionList: false,
    },
    editedStatusForm: {
      statusName: "",
      statusColor: {id: "", colour: "", disabled: false},
      statusDescription: "",
      activeStatus: false,
      hasNameError: false,
      hasDescriptionError: false,
      hasColorError: false,
    },
  },
  mutations: {
    SET_CASE_STATUS_LIST(state, payload) {
      state.caseStatusList = payload;
    },
    SET_CASE_STATUS_COLORS_LIST(state, payload) {
      state.colorList = payload;
    },
    SET_UPDATED_COLOR_LIST(state, payload) {
      state.updatedColors = payload;
    },
    SET_TRIGGER_LIST(state, payload) {
      state.triggerList = payload;
    },
    SET_ACTION_LIST(state, payload) {
      state.triggerList = payload;
    },
    SET_TRIGGER_AND_ACTION_DATA(state, payload) {
      state.triggerList = payload;
    },
    SET_TRANSITION_LIST(state, payload) {
      state.transitionList = payload;
    },
    SET_STATUS_NAME(state, payload) {
      state.editedStatusForm.statusName = payload;
    },
    SET_STATUS_DESCRIPTION(state, payload) {
      state.editedStatusForm.statusDescription = payload;
    },
    SET_STATUS_COLOR(state, payload) {
      state.editedStatusForm.statusColor = payload;
    },
    SET_STATUS_ACTIVE(state, payload) {
      state.editedStatusForm.activeStatus = payload;
    },
    SET_STATUS_NAME_VALIDATION_ERROR(state, payload) {
      state.editedStatusForm.hasNameError = payload;
    },
    SET_STATUS_DESCRIPTION_VALIDATION_ERROR(state, payload) {
      state.editedStatusForm.hasDescriptionError = payload;
    },
    SET_STATUS_COLOR_VALIDATION_ERROR(state, payload) {
      state.editedStatusForm.hasColorError = payload;
    }
  },
  getters: {
    getName: (state) => state.name,
    getWorkflowCaseStatusList: (state) => state.caseStatusList,
    getWorkflowStateTransitionList: (state) => state.transitionList,
    getCaseColorList: (state) => state.getCaseColorList,
    getUpdatedColorList: (state) => state.updatedColors,
    getTriggerList: (state) => state.triggerList,
    getActionList: (state) => state.actionList,
    getLoaders: (state) => state.loaders,
    getEditStatusFormData: (state) => state.editedStatusForm
  },
  actions: {
    getApiParams(_, payload) {
      switch (payload.funName) {
        case "fetchCaseStatusList": {
          if (payload.type === WORK_FLOW_TYPE.GLOBAL_CASE) {
            return { param1: "package-status", param2: "pacakge_status" };
          } else if (payload.type === WORK_FLOW_TYPE.GLOBAL_CHECKS) {
            return { param1: "check-stages", param2: "check_status" };
          }
          break;
        }
        case "fetchAllColors": {
          if (payload.type === WORK_FLOW_TYPE.GLOBAL_CASE) {
            return { param1: "package-status", param2: "case-status" };
          } else if (payload.type === WORK_FLOW_TYPE.GLOBAL_CHECKS) {
            return { param1: "check-stages", param2: "check-status" };
          }
          break;
        }
        default: {
          if (payload.type === WORK_FLOW_TYPE.GLOBAL_CASE) {
            return { param1: "package-status", param2: "pacakge_status" };
          } else if (payload.type === WORK_FLOW_TYPE.GLOBAL_CHECKS) {
            return { param1: "check-stages", param2: "check_status" };
          }
          break;
        }
      }
    },
    // async fetchCaseStatusList({ commit, state,  }, ) {
    //   state.loaders.statusList = true;
    //   try {
    //     // let apiParams = await dispatch("getApiParams", {
    //     //   type: payload.type,
    //     //   funName: "fetchCaseStatusList",
    //     // });
    //     // let url = `/${apiParams.param1}/get_${apiParams.param2}_list?tenant_id=${payload.id}`;
    //     const response = await fetchStatusList();
    //     await commit("SET_CASE_STATUS_LIST", response?.data?.data);
    //     state.loaders.statusList = false;
    //   } catch (err) {
    //     console.log("err", err);
    //     Vue.$toast.error(
    //       err?.response?.data?.detail || this.$t("general.default_error")
    //     );
    //     state.loaders.statusList = false;
    //   }
    // },

    async transitionList({ commit, state }, { type, workflowId }) {
      state.loaders.transitionList = true;
      try {
        const response = await fetchStateTransitionList(type, workflowId);
        if (response.status) {
          await commit("SET_TRANSITION_LIST", response?.data?.data);
        }
        state.loaders.transitionList = false;
      } catch (err) {
        console.error("err", err);
        Vue.$toast.error(
          err?.response?.data?.detail || this.$t("general.default_error")
        );
        state.loaders.transitionList = false;
      }
    },
    async fetchAllColors({ commit, dispatch }) {
      try {
        const response = await fetchWorkflowMasterColors();
        if (response.status) {
          let colorList = response?.data?.data?.map((color) => ({
            ...color,
            disabled: false,
          }));
          commit("SET_CASE_STATUS_COLORS_LIST", colorList);
          await dispatch("setDisabledColor");
        } else {
          Vue.$toast.error(
            response?.data?.status_message || this.$t("general.default_error")
          );
        }
      } catch (err) {
        console.log("err", err);
        Vue.$toast.error(err.response?.data?.detail || this.$t("general.default_error"));
      }
    },
   async setDisabledColor({ state, commit,}) {
      if (state?.transitionList?.length) {
        const usedColors = state.transitionList?.map((el) => el.colour_id);
        let updatedColorList = state.colorList?.map((el) => {
          if (usedColors.includes(el.id)) {
            return { ...el, disabled: true };
          }
          return el;
        });
        commit("SET_UPDATED_COLOR_LIST", updatedColorList);
      } else {
        commit("SET_UPDATED_COLOR_LIST", state.colorList);
      }
    },
    async createCaseStatus({ state, dispatch }, payload) {
      let flag = false;
      try {
        state.loaders.createStatus = true;
        let apiParams = await dispatch("getApiParams", {
          type: payload.type,
          funName: "createCaseStatus",
        });
        const url = `/${apiParams.param1}/create_${apiParams.param2}`;
        const response = await axios.post(url, payload.formPayload);
        if (response) {
          flag = true;
          Vue.$toast.success(
            response?.data?.message || this.$t("general.default_error")
          );
          state.loaders.createStatus = false;
        }
      } catch (err) {
        flag = false;
        console.log("err", err);
        Vue.$toast.error(
          err?.response?.data?.detail || this.$t("general.default_error")
        );
        state.loaders.createStatus = false;
      }
      return flag;
    },
    async fetchTriggerList({ state, commit }) {
      state.loaders.triggerList = true;
      try {
        const url = "/package-status/get_package_triggers";
        const response = await axios.get(url);
        commit("SET_TRIGGER_LIST", response?.data?.data);
        state.loaders.triggerList = false;
        //   console.log("data", data);
        //   this.triggerList = data?.data?.data;
      } catch (err) {
        state.loaders.triggerList = false;
        console.log("err", err);
        Vue.$toast.error(
          err?.response?.data?.detail || this.$t("general.default_error")
        );
      }
    },
    async fetchActionList({ state, commit }) {
      state.loaders.actionList = true;
      try {
        const url = "/package-status/get_package_triggers";
        const response = await axios.get(url);
        commit("SET_ACTION_LIST", response?.data?.data);
        state.loaders.actionList = false;
        //   console.log("data", data);
        //   this.triggerList = data?.data?.data;
      } catch (err) {
        state.loaders.actionList = false;
        console.log("err", err);
        Vue.$toast.error(
          err?.response?.data?.detail || this.$t("general.default_error")
        );
      }
    },
    async addTriggerAndAction({ state }) {
      state.loaders.triggerAndAction = true;
      // try {
      //     const url = ``;
      //     const response = await axios.post(url, payload);
      //     if(response) {
      //         console.log(response);
      //     }
      // } catch (err) {
      //     console.log("err", err);
      // }
    },
    async fetchTriggerAndActionData({ state, commit }) {
      state.loaders.triggersAndActionData = true;
      try {
        const url = "/package-status/get_package_triggers";
        const response = await axios.get(url);
        commit("SET_TRIGGER_AND_ACTION_DATA", response?.data?.data);
        state.loaders.triggersAndActionData = false;
        //   console.log("data", data);
        //   this.triggerList = data?.data?.data;
      } catch (err) {
        state.loaders.triggersAndActionData = false;
        console.log("err", err);
        Vue.$toast.error(
          err?.response?.data?.detail || this.$t("general.default_error")
        );
      }
    },
  },
};
