import axios from "@/axios";
const { orderBy } = require("lodash");
import { getCaseEntities } from '@shared/case-entities/services';
import { ENTITY_TYPE_NAMES } from "@shared/utils/constants.js";
import { getEntityTypeName, getEntityStatuses } from "@shared/utils/functions";
import {
    getEntitiesCounter,
    getEntitiesToAdd,
} from "@shared/case-creation/CaseCreationFlow/utils";

export const CaseStore = {
    state: {
        caseData: null,
        caseStatusList: [],
        caseEntitiesData: {},
        showAddNewEntity: false,
        newEntityInfo: {
            entity_type: "",
            entity_name: "",
            entity_type_id: "",
            temporary_name: "",
        },
        ableToAccessCase: false,
        caseDataLoading: false,
        checkDescription: "",
        selectedCheckLabel: "",
    },

    mutations: {
        SET_CASE_DETAILS(state, caseData ) {
            state.caseData = caseData;
        },
        SET_CASE_STATUS_LISTS(state, caselist){
            state.caseStatusList = orderBy(caselist, 'status_order', 'asc')
        },
        SET_SHOW_ADD_NEW_ENTITY(state, payload) {
            state.showAddNewEntity = payload;
        },
        SET_CASE_ENTITIES_DATA(state, payload) {
            state.caseEntitiesData = payload;
        },
        SET_NEW_ENTITY_INFO(state, payload) {
            state.newEntityInfo[payload.key] = payload.value;
        },
        SET_CASE_OPEN_ACCESS(state, payload){
            state.ableToAccessCase = payload;
        },
        CASE_DATA_LOADING(state, payload){
            state.caseDataLoading = payload;
        },
        SET_CHECK_DESCRIPTION(state, payload) {
            state.checkDescription = payload;
        },
        SET_SELECTED_CHECK_LABEL(state, payload) {
            state.selectedCheckLabel = payload;
        }
    },

    getters: {
        getCaseData: state => state.caseData,
        getNeoCaseId: state => state?.caseData?.neo_case_id,
        getCaseStatusList: state => state.caseStatusList,
        getCaseEntitiesData: state => state.caseEntitiesData,
        getShowAddNewEntity: state => state.showAddNewEntity,
        getNewEntityInfo: state => state.newEntityInfo,
        getEntitiesToAdd: state => {
            const entitiesCounter = getEntitiesCounter(state.caseEntitiesData);
            return getEntitiesToAdd(entitiesCounter);
        },
        getIsPlusDisabled: (_state, getters) => {
            return !getters.getEntitiesToAdd.length;
        },
        getCaseAccessible: state => state.ableToAccessCase,
        getCaseDataLoading: state => state.caseDataLoading,
        getCheckDescription: state => state.checkDescription,
        getSelectedCheckLabel: state => state.selectedCheckLabel,
    },

    actions: {
        fetchCaseData: async ({ commit }, payload) => {
            commit('CASE_DATA_LOADING', true);
            if(payload){
                let case_id = payload
                const url = `/case/${case_id}`;
                try {
                    let { data } = await axios.get(url);
                    sessionStorage.setItem("client_id", data?.data?.client_id)
                    commit('SET_CASE_DETAILS', data.data)
                    commit('SET_CASE_OPEN_ACCESS', true)
                    commit('CASE_DATA_LOADING', false);
                } catch (error) {
                    if(error?.response?.status === 403 ){
                        commit('SET_CASE_OPEN_ACCESS', false)
                        commit('CASE_DATA_LOADING', false);
                    }
                    console.log("error>>>", error)

                    // Vue.$toast.warning("case not found!");
                }
            }
        },

        fetchCaseStatusLists: async({commit}, payload) => {
            const url = `/workflow/${payload.case_id}/status`;
            try {
                let { data } = await axios.get(url);
                commit('SET_CASE_STATUS_LISTS', data.data)
            } catch (error) {
                console.log("error>>>", error);
                throw error;
                // Vue.$toast.warning("case not found!");
            }
        },

        fetchCaseEntitiesData: async({ commit }, case_id) => {
            try {
                let data = await getCaseEntities(case_id);

                const promises = data.case_entities
                    .filter(entity=> getEntityTypeName(data, entity) != ENTITY_TYPE_NAMES.CASE)
                    .map(entity => getEntityStatuses(entity.id));

                const entityStatuses = await Promise.all(promises);

                data.case_entities = data.case_entities.map(el => {
                    el.statusList = entityStatuses.find(x => x.id == el.id)?.data || []; 
                    return el;
                })

                commit("SET_CASE_ENTITIES_DATA", data);
            } catch (err) {
                console.error("err", err);
            }
        },

        resetNewEntityInfo({ commit, state }) {
            for (const key in state.newEntityInfo) {
                commit("SET_NEW_ENTITY_INFO", { key, value: "" });
            }
        }
    }
};
