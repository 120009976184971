import Vue from "vue";
import Toast from "vue-toastification";
import Vuelidate from "vuelidate";
import VueTippy from "vue-tippy";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "./fontawesome";
import "./vue-good-table";
import "./highcharts";
import "./apexcharts";
import "./vuevirtualscroll"
import VModal from "vue-js-modal";
// modules Css
import "vue-toastification/dist/index.css";
import { options } from "@shared/utils/vuetoastification";

Vue.use(VModal, { dialog: true });
Vue.use(Toast, options);
Vue.use(Vuelidate);
Vue.use(VueTippy, {
    directive: "tippy",
});
