import { getEntityByKeyList } from "@shared/components/form-builder/utils";
import { FIELDS_LIST } from "./constants";
import { isWithinInterval, format } from "date-fns";

export const resolveQueryString = (queryString) => {
  return isNaN(queryString) ? queryString : `$vid:${queryString}`;
};

export const resolveModel = (model) => {
  return Array.isArray(model.value)
    ? model.value.map(el => el.value)
    : model.value.value;
};

export const getAutopopulatedFiledValues = (sections) => {
  const fieldValues = {};
  for (const field of FIELDS_LIST) {
    const result = getEntityByKeyList(sections, { field_internal_name: field });
    fieldValues[field] = result?.value ?? ""
  }
  return fieldValues;
};

export const isDateWithinInterval = (date, interval) => {
  return isWithinInterval(new Date(date), interval);
}

export const formatCardDate = (date) => {
  if (!date?.length) return;
  return format(new Date(date), "dd MMMM yyyy");
};
