<!-- Localized -->
<template>
    <div
      v-if="config[0].show"
      class="split-view-modal flex-col h-screen w-screen flex items-center justify-center fixed top-0 bottom-0 right-0 left-0"
      @click.stop="config[0].onClose"
    >
      <div class="h-auto flex-col px-6 py-3 space-y-4 flex items-center justify-center" @click.stop>
        <div class="flex space-x-6">
          <template v-for="(view, i) in config">
            <div
              v-if="view.show"
              :key="view.name"
              class="split-view-modal__view max-h-full overflow-y-auto flex flex-col"
            >
              <div class="bg-primary py-2 px-4 rounded-t-xl text-white-text font-semibold flex items-center justify-between">
                <slot :name="`${view.name}:title`" />
                  <button
                    v-if="!config[i + 1]?.show"
                    class="cursor-pointer h-6 w-6 justify-center items-center flex"
                    @click="view.onClose"
                  >
                    <font-awesome-icon icon="times" class="text-white-text" />
                </button>
              </div>

              <div class="bg-card-bg rounded-b-xl flex-1 h-full flex overflow-y-auto max-w-full p-4">
                <slot :name="`${view.name}:content`" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "UISplitViewModal",

  props: {
    /**
     * @typedef {Object} ConfigItem
     * @property {string} name
     * @property {boolean} show
     * @property {function} onClose
    */
    /**
     * @type {ConfigItem[]}
     */
    config: {
      type: Array,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.split-view-modal {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;

  &__view {
    min-width: 450px;
  }
}
</style>
