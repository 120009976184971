<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { appList } from "@shared/utils/constants";
import {
  isRTL,
  setDocumentDir,
  setDocumentLang,
} from "@shared/plugins/i18n/utils";

export default {
  name: 'App',

  data() {
    return {
      currentApp: appList.TENANT,
    }
  },

  provide () {
    return {
      currentApp: this.currentApp,
      isCurrentAppAllowed: this.isCurrentAppAllowed,
    }
  },

  computed: {
    lang() {
      return this.$i18n.locale;
    },
    isRTL() {
      return isRTL(this.lang);
    },
  },

  mounted() {
    setDocumentDir(this.isRTL);
    setDocumentLang(this.lang);
  },

  watch: {
    isRTL(val) {
      setDocumentDir(val);
    },
    lang(val) {
      setDocumentLang(val);
    },
  },

  methods: {
    isCurrentAppAllowed(list) {
      return list.includes(this.currentApp);
    },
  },
}
</script>

<style>
#app {
  height: 100vh;
}
</style>
