import { default as ax } from "axios";
import VueCookies from "vue-cookies";

// import store from "@/store";
// import { APP_CONFIG } from "@/utils/constants";
import refreshTokenInterceptor from "./utils.js";
import {getBaseUrlDelviumBackend} from "@shared/utils/functions"

import i18n from "@shared/plugins/i18n";

let axios = ax.create({
    baseURL: getBaseUrlDelviumBackend()
});


axios.interceptors.request.use(
    (config) => {
        const lang = localStorage.getItem("lang") || i18n.locale;
        // localStorage's lang - selected by user; i18n.locale - actually used
        if (lang)
            config.headers["Accept-Language"] = lang;

        let token = VueCookies.get("token");
        if (token) {

            config.headers["Authorization"] = `Bearer ${token}`;
            /*
            To keep some apis working ex(case list api) which dependes upon some header values,
            use this function.
            */
            // config.headers["X-Source"] = `(saas-platform) ${APP_CONFIG.name}/${APP_CONFIG.version}`;
            // config.headers["X-User-ID"] = store.getters.getUserAuthDetails.userId;
            // config.headers["X-CASE-ID"] = store.getters.getCaseId == null ? "" : store.getters.getCaseId;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(undefined, refreshTokenInterceptor(axios));
export default axios;
