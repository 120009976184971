import Vue from "vue";
import VueCookies from "vue-cookies";
import router from "@/routes";
import { logoutFromSaas, getSsoRedirectUrl, getRegistrationSsoRedirectUrl } from "./service";

export const AuthStore = {
  state: {
    user: null,
    userToken: null,
    userLoggedIn: false,
    // new flow
    userEmail: "",
    globalAuthData: null,
    ssoRedirectLoading: false,
    selfRegistrationData: null,
    sendOtpPayload: null,
    loginViaPassword: false,
    unifiedPortalState: null,
    selectedOrgData: null,
    selfRegistrationUserDetail: null,
  },

  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_LOGIN_STATE(state, payload) {
      state.userLoggedIn = payload;
    },
    SET_USER_TOKEN(state, payload) {
      state.userToken = payload;
      VueCookies.set("token", payload);
    },
    SET_USER_EMAIL(state, payload) {
      state.userEmail = payload;
    },
    SET_GLOBAL_AUTH_DATA(state, payload) {
        state.globalAuthData = payload;
    },
    SET_SELF_REGISTRATION_DATA(state, payload) {
      state.selfRegistrationData = payload;
    },
    SET_SSO_REDIRECT_LOADING(state, payload) {
      state.ssoRedirectLoading = payload;
    },
    SET_SEND_OTP_PAYLOAD(state, payload) {
      state.sendOtpPayload = payload;
    },
    SET_LOGIN_VIA_PASSWORD(state, payload) {
      state.loginViaPassword = payload;
    },
    SET_UNIFIED_PORTAL_STATE(state, payload) {
      state.unifiedPortalState = payload;
    },
    SET_SELF_REGISTRATION_USER_DETAIL(state, payload) {
      state.selfRegistrationUserDetail = payload;
    }
  },

  getters: {
    getUser: (state) => state.user,
    userToken: (state) => state.userToken,
    userLoggedIn: (state) => state.userLoggedIn,
    getUserEmail: (state) => state.userEmail,
    getGlobalAuthData: (state) => state.globalAuthData,
    geSelfRegistrationData: (state) => state.selfRegistrationData,
    getSsoRedirectLoading: (state) => state.ssoRedirectLoading,
    getSendOtpPayload: (state) => state.sendOtpPayload,
    getLoginViaPassword: (state) => state.loginViaPassword,
    getUnifiedPortalState: (state) => state.unifiedPortalState,
    getSelectedOrgData: (state) => state.selectedOrgData,
    getSelfRegUserDetail: (state) => state.selfRegistrationUserDetail
  },

  actions: {
    setLoginSession({ commit }, payload) {
      commit("SET_USER", payload.user);
      commit("SET_LOGIN_STATE", payload.userLoggedIn);
      VueCookies.set("token", payload.token);
      commit("SET_USER_TOKEN", payload.token);
    },
    setNewUserToken({ commit }, payload) {
      VueCookies.set("token", payload);
      commit("SET_USER_TOKEN", payload);
    },
    async logoutUser() {
      let getLogoutUri;
      try {
        getLogoutUri = await logoutFromSaas();
      } catch (error) {
        if (error === "LogoutFailed") {
          console.log("Logout!");
        }
      } finally {
        VueCookies.remove("token");
        VueCookies.remove("refresh_token");
        localStorage.removeItem("theme");
        localStorage.removeItem("themeObj");
        // VueCookies.remove("ACCOUNT_ID")
        const loginPath = router.resolve({ name: "Login" });
        // VueCookies.set("next-url", loginPath.href);
        // sessionStorage.removeItem("isTenant")
        // sessionStorage.removeItem("isClient")

        window.location.href = getLogoutUri || loginPath.href;
      }
    },
    manageGlobalAuthData({commit}, payload) {
        commit("SET_GLOBAL_AUTH_DATA", payload);
    }, 
    manageSelfRegistrationData({commit}, payload) {
      commit("SET_SELF_REGISTRATION_DATA", payload);
    },
    manageSendOtpData({commit}, payload){
      commit("SET_SEND_OTP_PAYLOAD", payload);
    },
    isLoginViaPassword({commit}, payload){
      commit("SET_LOGIN_VIA_PASSWORD", payload);
    },
    setUnifiedPortalState({commit},payload) {
      commit("SET_UNIFIED_PORTAL_STATE", payload)
    },
    setSelectedOrgData({state}, payload) {
      state.selectedOrgData = payload
    },
    setSelfRegUserDetail({commit}, payload) {
      commit("SET_SELF_REGISTRATION_USER_DETAIL", payload);
    },
    async ssoRedirectLogin({commit}, {payload, userType}) {
      try {
        commit("SET_SSO_REDIRECT_LOADING", true);
         
          const response = userType === 'new' ? await getRegistrationSsoRedirectUrl(payload) : await getSsoRedirectUrl(payload);
          if (response?.data?.auth_uri) {
            window.location.href = response?.data?.auth_uri;
          } else {
            Vue.$toast.error("link not found.");
          }
          commit("SET_SSO_REDIRECT_LOADING", false);
      } catch (err) {
        console.log("err", err);
        Vue.$toast.error(
          err?.response?.data?.detail || "something went wrong"
        );
        commit("SET_SSO_REDIRECT_LOADING", false);
      }
    },
  },
};
