export const options = {
  // You can set your default options here
  position: "top-right",
  newestOnTop: true,
  maxToasts: 20,
  timeout: 5000,
  pauseOnFocusLoss: false,
  // icon: false
  // transition: "Vue-Toastification__bounce",
  hideProgressBar: false,
  transition: {
      enter: "fade-enter-active",
      leave: "Vue-Toastification__bounce-leave-active",
      move: "fade-move",
  },
};
