import axios from "./workflow-axios";

export async function getPackageTriggers() {
  const url = "/package-status/get_package_triggers";
  return await axios.get(url);
}

export async function fetchStatusList(type, workflowId = "") {
  const url = workflowId
    ? `/workflow/${type}?workflow_id=${workflowId}`
    : `/workflow/${type}`;
  return await axios.get(url);
}

export async function reorderedTransitionList(type, payload) {
  const url = `/workflow/${type}/status/reorder`;
  return await axios.put(url, payload);
}

export async function createWorkflow(type, payload) {
  const url = `/workflow/${type}`;
  return await axios.post(url, payload);
}

export async function updateWorkflow(type, payload) {
  const url = `/workflow/${type}`;
  return await axios.put(url, payload);
}

export async function cloneWorkflow(type, {workflow_id}) {
  const url = `/workflow/${type}/clone/${workflow_id}`;
  return await axios.post(url);
}
export async function fetchWorkflowMasterColors() {
  const url = `/workflow/master/color`;
  return await axios.get(url);
}
export async function fetchStateTransitionList(type, workflowId) {
  const url = `/workflow/${type}/status/${workflowId}`;
  return await axios.get(url);
}

export async function createStatus(type = "case", payload) {
  const url = `/workflow/${type}/status`;
  return await axios.post(url, payload);
}

export async function getTriggerEvents(type = "case") {
  const url = `/workflow/${type}/event-group`;
  const { data } = await axios.get(url);
  return data.data;
}

export async function getTriggerActions(type = "case") {
  const url = `/workflow/${type}/action`;
  const { data } = await axios.get(url);
  return data.data;
}

export async function getTriggerActionsConfig(
  type = "case",
  actionType,
  workflowId
) {
  const url = `/workflow/${type}/action?action_type=${actionType}&workflow_id=${workflowId}`;
  const { data } = await axios.get(url);
  return data.data;
}

export async function createTrigger(type = "case", payload) {
  const url = `/workflow/${type}/triggers`;
  return await axios.post(url, payload);
}

export async function getAllTriggers(type = "case", workflowId) {
  const url = `/workflow/${type}/triggers?workflow_id=${workflowId}`;
  const data = await axios.get(url);
  return data.data;
}

export async function getTriggerDetails(type = "case", workflowId, triggerId) {
  const url = `/workflow/${type}/triggers?workflow_id=${workflowId}&trigger_id=${triggerId}`;
  const { data } = await axios.get(url);
  return data.data;
}
export async function updateTrigger(type="case", payload) {
  const url = `/workflow/${type}/triggers`
  return await axios.put(url, payload)
}

// export async function getAllTriggers(type="case",workflowId) {
//   const url = `/workflow/${type}/triggers?workflow_id=${workflowId}`
//   const data = await axios.get(url)
//   return data.data
// }

export async function getAllCaseWorkflows(type = "case") {
  const url = `/workflow/${type}`;
  const { data } = await axios.get(url);
  return data.data;
}

export async function getAllCaseWorkflowsPromise(type = "case") {
  return axios.get(`/workflow/${type}`)
}

export async function activateTrigger(type = "case", payload) {
  const url = `/workflow/${type}/triggers/toggle`;
  const { data } = await axios.put(url, payload);
  return data.data;
}

export async function fetchTenantGroups(tenantId) {
  const { data } = await axios.get(`/group/tenant/${tenantId}`);
  return data ?? [];
}

export async function fetchClientGroups() {
  const { data } = await axios.get("group/client-group/bulk");
  return data ?? [];
}

export async function deleteStatus(type, payload) {
  const url = `/workflow/${type}/status/delete`;
  return await axios.delete(url, { data: payload });
}

export async function fetchStatusDetails(type, statusId) {
  const url = `/workflow/${type}/status/details/${statusId}`;
  return await axios.get(url);
}

export async function updateStatusTransition(type, payload) {
  const url = `/workflow/${type}/status/update`;
  return await axios.put(url, payload);
}

export async function getPackageWorkflow(type="case", packageId) {
  const url = `/workflow/${type}/link/${packageId}`
  const {data} = await axios.get(url)
  return data.data
}

export async function getPackageWorkflowPromise(type="case", packageId) {
  const url = `/workflow/${type}/link/${packageId}`
  return await axios.get(url)
}

export async function linkPackageWorkflow(type="case", workflowId, packageId) {
  const url = `/workflow/${type}/link/${workflowId}/${packageId}`
  return await axios.post(url)
}

export async function linkPackageWorkflowPromise(type="case", workflowId, packageId) {
  const url = `/workflow/${type}/link/${workflowId}/${packageId}`
  return axios.post(url)
}

export async function cloneTrigger(type="case", workflowId, triggerId) {
  const url = `/workflow/${type}/triggers/clone/${workflowId}/${triggerId}`
  const {data} = await axios.post(url)
  return data.data
}

export async function fetchRiskManagementDetail(configId) {
  let url = configId ? `risk-assessment?config_id=${configId}` :`risk-assessment`;
  return await axios.get(url);
}