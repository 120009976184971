export const VLEX_SCREENS = Object.freeze({
  Form: "form",
  Results: "results",
});

export const PER_PAGE_OPTIONS = Object.freeze([10, 20, 50, 100]);

export const DEFAULT_PAGINATION = {
  page: 1,
  perPage: PER_PAGE_OPTIONS[0],
  total_count: 0
};

export const DROPDOWN_CONFIG = Object.freeze({
  checkboxes: false,
  closeOnSelect: true,
  groupLabel: "group",
  groupSelect: true,
  groupValues:"categories",
  label: "name",
  limit: 3,
  multiple: false,
  showLabels: false,
  tagPlaceholder: "",
  trackBy: "name",
});

export const FIELDS_LIST = [
  "person_first_name",
  "person_middle_name",
  "person_last_name",
  "person_full_name",
  "company_name",
  "country",
];
