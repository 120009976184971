<template>
  <div>
    <div class="w-full bg-white p-4 border-2 rounded-lg mx-auto mt-4" v-for="item in count" :key="item">
      <div class="flex animate-pulse flex-row items-center h-full space-x-5">
        <div class="flex flex-grow flex-col space-y-3">
          <div class="w-9/12 bg-gray-300 h-6 rounded-md"></div>
          <div class="bg-gray-300 h-6 rounded-md"></div>
          <div class="bg-gray-300 h-6 rounded-md"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UISkeleton",

  props: {
    count: {
      type: Number,
      required: true,
    }
  }
}
</script>
