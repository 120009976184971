import { isDateWithinInterval } from "../vLex/utils";

export const applyFilters = (state, data) => {
  let result = [...data];
      const { query, jurisdiction, date, docketNumber } = state.filters;

      if (query.length > 0) {
        result = result
          .filter(el => el?.data?.title?.toLowerCase().includes(query.toLowerCase()));
      }

      if (jurisdiction.length > 0) {
        result = result
          .filter(el => jurisdiction.includes(el?.data?.jurisdiction?.id));
      }

      if (date.start && date.end) {
        result = result
          .filter(el => isDateWithinInterval(el?.data?.meta?.["Judgment Date"], date));
      }

      if (docketNumber?.length > 0) {
        result = result
          .filter(el => el?.data?.meta?.["Docket Number"] === docketNumber);
      }

      return result;
}