export const License = [
    {
        path: "/license",
        component: () => import(/* webpackChunkName: "license-pack" */ "@/pages/license/index.vue"),
        meta: {
            breadcrumb: [{ name: "Admin", tName: 'routes.admin' }, { name: "License", tName: 'routes.license' }],
            title: "License",
        },
        children: [
            {
                path: "/",
                name: "license-list",
                component: () => import(/* webpackChunkName: "license list" */ "@/pages/license/license-list/index.vue"),
                meta: {
                    breadcrumb: [{ name: "Admin", tName: 'routes.admin' }, { name: "License", tName: 'routes.license' }, { name: "License Lists", tName: 'routes.license_lists' }],
                    title: "License",
                },
            },
            {
                name: "license-details",
                path: "details/:id/:tab?",
                component: () => import(/* webpackChunkName: "license details" */ "@/pages/license/license-details/index.vue"),
                meta: {
                    breadcrumb: [{ name: "Admin", tName: 'routes.admin' }, { name: "License", tName: 'routes.license' }, { name: "License Lists", tName: 'routes.license_lists' }, {name: 'details', tName: 'routes.details'}],
                    title: "License",
                    requiredPermission: 'license.edit'
                },
            },
        ],
    },
];
